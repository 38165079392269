import React from "react";
import NotFoundContent from "../components/NotFoundContent";
import Footer from "../components/Footer";
import Nav from "../components/Nav";

const NotFound = () => {
  return (
    <>
      <div className="flex flex-col justify-between min-h-screen">
        <Nav />
        <NotFoundContent />
        <Footer />
      </div>
    </>
  );
};

export default NotFound;
