import React from 'react'
import Nav from '../components/Nav'
import Convert from '../components/Convert'
import Footer from '../components/Footer'

const Home = () => {
  return (
    <>
      <div className="flex flex-col justify-between min-h-screen">
        <Nav />
        <Convert />
        <Footer />
      </div>
    </>
  )
}

export default Home
