import React from "react";
import Nav from "../components/Nav";
import Footer from "../components/Footer";
import ContactForm from "../components/ContactForm";

const Contact = () => {
  return (
    <div className="flex flex-col justify-between min-h-screen">
      <Nav />
      <ContactForm/>
      <Footer />
    </div>
  );
};

export default Contact;
