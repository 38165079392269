import React from "react";

const AboutContent = () => {
  return (
    <div className="mb-auto bg-gray-300 flex items-center justify-center mx-12 md:mx-6 h-full mt-24 md:mt-24">
      <div className="flex flex-col gap-y-8 items-center mb-6 justify-center py-6">
        <div className="bg-white p-6 rounded-lg shadow-lg">
          <h1 className="text-3xl font-bold mb-4 text-gray-800">
            Image Converter
          </h1>
          <p className="text-gray-600 mb-4">
            Welcome to our Image Converter! Our tool allows you to convert
            images between JPEG, PNG, and HEIC formats easily and for free.
            Whether you need to change a PNG to a JPG, or a HEIC to a PNG, our
            converter has you covered.
          </p>

          <h2 className="text-2xl font-semibold mb-2 text-gray-800">
            Why Choose Our Image Converter?
          </h2>
          <p className="text-gray-600 mb-4">
            Our image converter is designed with simplicity and efficiency in
            mind. Here are some reasons why you should use our tool:
          </p>
          <ul className="list-disc list-inside text-gray-600 mb-4">
            <li>
              Free and Unlimited Conversions: Convert as many images as you need
              without any restrictions.
            </li>
            <li>
              No Data Storage: We do not store any of your data, ensuring your
              privacy is protected.
            </li>
            <li>
              Support for Multiple Formats: Easily convert JPEG, PNG, and HEIC
              images.
            </li>
            <li>
              Fast and Efficient: Our converter is optimized for quick and
              hassle-free conversions.
            </li>
          </ul>

          <h2 className="text-2xl font-semibold mb-2 text-gray-800">
            How to Use Our Image Converter
          </h2>
          <p className="text-gray-600 mb-4">
            Using our image converter is simple:
          </p>
          <ol className="list-decimal list-inside text-gray-600 mb-4">
            <li>Upload your image in JPEG, PNG, or HEIC format.</li>
            <li>Select the format you want to convert to.</li>
            <li>Click the 'Convert' button and wait a few seconds.</li>
            <li>Download your converted image.</li>
          </ol>

          <h2 className="text-2xl font-semibold mb-2 text-gray-800">
            Security and Privacy
          </h2>
          <p className="text-gray-600 mb-4">
            We take your privacy seriously. Our image converter operates without
            storing any data on our servers. This means that your images are
            never saved or logged, ensuring complete confidentiality.
          </p>

          <h3 className="text-xl font-semibold mb-2 text-gray-800">
            Is the image converter really free?
          </h3>
          <p className="text-gray-600 mb-4">
            Yes, our image converter is completely free to use without any
            hidden charges or limitations.
          </p>
          <h3 className="text-xl font-semibold mb-2 text-gray-800">
            Do you store my images?
          </h3>
          <p className="text-gray-600 mb-4">
            No, we do not store any images on our servers. Your data is never
            logged, ensuring your privacy is maintained.
          </p>
        </div>
      </div>
    </div>
  );
};

export default AboutContent;
