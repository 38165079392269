import React, { useEffect, useState } from "react";
import "../index.css";
import toast, { Toaster } from "react-hot-toast";
import Config from "../config/Config";

const Convert = () => {
  const [files, setFiles] = useState([]);
  const [convertedFiles, setConvertedFiles] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [format, setFormat] = useState("JPEG");
  const [uploaded, setUploaded] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {}, [uploaded, convertedFiles, files, errorMessage, format]);

  const handleFiles = (files) => {
    const validFiles = Array.from(files).filter((file) => {
      if (file.size > 20 * 1024 * 1024) {
        toast.error(`File ${file.name} is larger than 20 MB.`, {
          style: {
            marginTop: "5px",
          },
        });
        return false;
      }
      return true;
    });

    if (validFiles.length > 0) {
      setFiles(validFiles);
      setConvertedFiles([]);
      setUploaded(true);
    }
  };

  const handleRemoveFile = (index) => {
    const newFiles = [...files];
    newFiles.splice(index, 1);
    if (newFiles.length === 0) {
      setLoading(false);
      setUploaded(false);
      setFiles([]);
    } else {
      setFiles(newFiles);
    }
  };

  const handleSubmit = async (event) => {
    setLoading(true);
    setUploaded(false);
    event.preventDefault();
    setErrorMessage("");

    const formData = new FormData();
    files.forEach((file) => {
      formData.append("files", file);
    });
    formData.append("format", format);

    try {
      const response = await fetch(Config.url + "/convert", {
        method: "POST",
        body: formData,
      });

      if (!response.ok) {
        const error = await response.json();
        setErrorMessage(error.error);
        return;
      }

      const result = await response.json();
      setConvertedFiles(result.files);
    } catch (error) {
      setErrorMessage("An error occurred while uploading the file.");
    } finally {
      setLoading(false);
    }
  };

  const formatFileSize = (size) => {
    return (size / (1024 * 1024)).toFixed(2) + " MB";
  };

  return (
    <>
      <Toaster />
      <div className="mb-auto bg-gray-300 flex items-center justify-center mx-12 md:mx-6 h-full mt-48 md:mt-60">
        <div className="flex flex-col gap-y-8 items-center mb-6">
          <div className="flex flex-col gap-y-2 items-start justify-start">
            <h1 className="mb-4 text-4xl sm:text-5xl md:text-6xl font-extrabold leading-none tracking-tight text-gray-900 dark:text-white">
              <span>Image </span>
              <mark className="px-2 text-white bg-green-600 rounded dark:bg-blue-500">
                Converter
              </mark>
              <span className="hidden sm:inline-block bg-blue-100 text-blue-800 text-2xl font-semibold me-2 px-2.5 py-0.5 rounded dark:bg-blue-200 dark:text-blue-800 ms-2 m-2">
                BETA
              </span>
            </h1>
            <p className="text-lg font-normal text-gray-900 lg:text-xl dark:text-gray-400">
              Image Converter converts your image files online. For now, we only
              support PNG, JPG and HEIC.
            </p>
          </div>
          <div className="w-full mx-auto p-6 bg-white shadow-md rounded-lg h-full">
            <form
              id="uploadForm"
              onSubmit={handleSubmit}
              className="text-center"
            >
              <div
                className="w-full relative border-2 border-gray-300 border-dashed rounded-lg p-6"
                id="dropzone"
              >
                <input
                  type="file"
                  id="fileInput"
                  name="files"
                  accept=".heic,.png,.jpg,.jpeg"
                  multiple
                  className="absolute inset-0 w-full h-full opacity-0 z-50"
                  onChange={(e) => handleFiles(e.target.files)}
                />
                <div className="text-center">
                  <img
                    className="mx-auto h-12 w-12"
                    src="https://www.svgrepo.com/show/357902/image-upload.svg"
                    alt=""
                  />
                  <h3 className="mt-2 text-sm font-medium text-gray-900">
                    <label htmlFor="files" className="relative cursor-pointer">
                      <span>Drag and drop</span>
                      <span className="text-indigo-600"> or browse</span>
                      <span> to upload</span>
                    </label>
                  </h3>
                  <p className="mt-1 text-xs text-gray-500">
                    PNG, JPG, HEIC up to 20MB
                  </p>
                </div>
              </div>
              {uploaded && (
                <>
                  <select
                    id="formatSelect"
                    name="format"
                    value={format}
                    onChange={(e) => setFormat(e.target.value)}
                    className="mt-4 p-2 border border-gray-300 rounded-md"
                  >
                    <option value="JPEG">JPEG</option>
                    <option value="PNG">PNG</option>
                    <option value="HEIC">HEIC</option>
                  </select>
                  <button
                    type="submit"
                    className="bg-green-600 text-white mx-2 py-2 px-4 rounded hover:bg-blue-700"
                  >
                    Convert
                  </button>
                </>
              )}
            </form>

            {loading && (
              <div className="mt-4 flex justify-center items-center">
                <div role="status">
                  <svg
                    aria-hidden="true"
                    class="w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                    viewBox="0 0 100 101"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                      fill="currentColor"
                    />
                    <path
                      d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                      fill="currentFill"
                    />
                  </svg>
                  <span class="sr-only">Loading...</span>
                </div>
              </div>
            )}

            {uploaded && (
              <>
                <hr className="my-4 h-0.5 bg-gray-300 dark:bg-white/10" />
                <div id="output" className="mt-8 text-center">
                  <div className="grid grid-cols-1 gap-y-3 shadow-lg">
                    {files.map((file, index) => (
                      <div
                        key={index}
                        className="border p-4 rounded-lg flex flex-col items-center relative"
                      >
                        <button
                          onClick={() => handleRemoveFile(index)}
                          className="absolute top-2 right-2 bg-red-600 text-white rounded-full w-6 h-6 flex items-center justify-center hover:bg-red-800"
                        >
                          <span class="sr-only">Close menu</span>
                          <svg
                            class="h-5 w-5"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            aria-hidden="true"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M6 18L18 6M6 6l12 12"
                            />
                          </svg>
                        </button>
                        <img
                          src={URL.createObjectURL(file)}
                          className="w-20 h-20 object-cover mb-2"
                        />
                        <p className="truncate w-full">
                          <strong>Name: </strong>
                          {file.name}
                        </p>
                        <p className="truncate">
                          <strong>Size: </strong>
                          {formatFileSize(file.size)}
                        </p>
                        <p className="truncate">
                          <strong>Type: </strong>
                          {file.type.split("/")[1]?.toUpperCase()}
                        </p>
                      </div>
                    ))}
                  </div>
                </div>
              </>
            )}

            {convertedFiles.length > 0 && (
              <>
                <hr className="my-6 h-0.5 bg-gray-300 dark:bg-white/10" />
                <div id="output" className="mt-8 text-center">
                  <div className="grid grid-cols-1 gap-y-3 shadow-lg">
                    {convertedFiles.map((file, index) => (
                      <div
                        key={index}
                        className="border p-4 rounded-lg flex flex-col items-center"
                      >
                        <img
                          src={file.url}
                          className="w-20 h-20 object-cover mb-2"
                          alt={file.name}
                        />
                        <p className="truncate w-full">
                          <strong>Name: </strong>
                          {file.name}
                        </p>
                        <p>
                          <strong>Size: </strong>
                          {formatFileSize(file.size)}
                        </p>
                        <p>
                          <strong>Type: </strong>
                          {file.type.split("/")[1]?.toUpperCase()}
                        </p>
                        <a href={file.url} download={file.name}>
                          <button className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-700 mt-2">
                            Download
                          </button>
                        </a>
                      </div>
                    ))}
                  </div>
                </div>
              </>
            )}

            {errorMessage && (
              <div id="errorMessage" className="text-red-500 mt-4">
                {errorMessage}
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Convert;
