import React from 'react'
import Footer from '../components/Footer'
import Nav from '../components/Nav'
import AboutContent from '../components/AboutContent'

const About = () => {
  return (
    <div className="flex flex-col justify-between min-h-screen">
      <Nav />
      <AboutContent />
      <Footer />
    </div>
  )
}

export default About
