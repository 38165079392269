import React from "react";
import { useNavigate } from "react-router-dom";

const NotFoundContent = () => {
  const navigate = useNavigate();
  return (
    <div className="mb-auto bg-gray-300 flex items-center justify-center mx-12 md:mx-6 h-full mt-24 md:mt-24">
      <div className="flex flex-col gap-y-8 items-center mb-6">
        <div class="py-8 px-4 lg:py-16 lg:px-6">
          <div class="text-center">
            <h1 class="mb-4 text-7xl tracking-tight font-extrabold lg:text-9xl text-green-600">
              404
            </h1>
            <p class="mb-4 text-3xl tracking-tight font-bold text-gray-900 md:text-4xl">
              Something's missing.
            </p>
            <p class="mb-4 text-lg font-light text-gray-500 ">
              Sorry, we can't find that page. You'll find lots to explore on the
              home page.{" "}
            </p>
            <button
              onClick={() => navigate("/")}
              className="inline-flex text-white bg-green-600 hover:bg-primary-800 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center my-4"
            >
              Back to Homepage
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotFoundContent;
