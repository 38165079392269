import React from "react";

const PricingContent = () => {
  return (
    <div className="mb-auto bg-gray-300 flex items-center justify-center mx-12 md:mx-6 h-full mt-24 md:mt-24">
      <div className="flex flex-col gap-y-8 items-center mb-6 justify-center py-6">
        <div className="bg-white p-6 rounded-lg shadow-lg">
          <h1 className="text-3xl font-bold mb-4 text-gray-800">
            No Pricing
          </h1>
          <p className="text-gray-600 mb-4">
            Our Image Converter is completely free
            to use with no hidden charges or limitations. Whether you need to
            convert a PNG to JPG, HEIC to PNG, or any combination of JPEG, PNG,
            and HEIC formats.
          </p>

          <h2 className="text-2xl font-semibold mb-2 text-gray-800">
            Why Our Image Converter is Free
          </h2>
          <ul className="list-disc list-inside text-gray-600 mb-4">
            <li>
              Completely Free: No hidden charges, no subscription fees, and no
              limitations. Convert as many images as you need without any cost.
            </li>
            <li>
              Privacy Guaranteed: We do not store any of your data. Your images
              are processed in real-time and are never saved on our servers.
            </li>
            <li>
              Multiple Format Support: Easily convert JPEG, PNG, and HEIC images
              to any of the supported formats.
            </li>
            <li>
              Fast and Efficient: Our converter is optimized for quick and
              hassle-free conversions.
            </li>
          </ul>

          <h3 className="text-xl font-semibold mb-2 text-gray-800">
            Is the image converter really free?
          </h3>
          <p className="text-gray-600 mb-4">
            Yes, our image converter is completely free to use without any
            hidden charges or limitations.
          </p>
          <h3 className="text-xl font-semibold mb-2 text-gray-800">
            Do you store my images?
          </h3>
          <p className="text-gray-600 mb-4">
            No, we do not store any images on our servers. Your data is never
            logged, ensuring your privacy is maintained.
          </p>
        </div>
      </div>
    </div>
  );
};

export default PricingContent;
