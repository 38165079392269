import React, { useState } from "react";
import LogoSVG from "../assets/img.svg";
import { useNavigate } from "react-router-dom";

const Nav = () => {
  const navigate = useNavigate();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <nav className="bg-gray-200 border-gray-200 dark:bg-gray-900 w-screen fixed top-0 z-50">
      <div className="max-w-screen-2xl flex flex-wrap items-center justify-between mx-auto p-4">
        {/* Logo and Text */}
        <a
          onClick={() => navigate("/")}
          className="flex items-center space-x-1 hover:cursor-pointer"
        >
          <img src={LogoSVG} className="h-8" alt="Logo" />
          <span className="self-center text-2xl font-semibold dark:text-white">
            IConverter
          </span>
        </a>

        {/* Contact and menu button */}
        <div className="flex md:order-2 space-x-3 md:space-x-0 rtl:space-x-reverse">
          <button
            type="button"
            onClick={() => navigate("/contact")}
            className="text-white bg-green-600 hover:bg-green-700 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
          >
            Contact
          </button>
          <button
            id="1453"
            data-collapse-toggle="navbar-cta"
            type="button"
            className="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-black rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
            aria-controls="navbar-cta"
            aria-expanded={isMenuOpen} // Dinamik olarak menünün durumunu belirtiyoruz
            onClick={toggleMenu} // Butona tıklayınca menüyü açıp kapatma fonksiyonu
          >
            <span className="sr-only">Open main menu</span>
            <svg
              className="w-5 h-5"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 17 14"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M1 1h15M1 7h15M1 13h15"
              />
            </svg>
          </button>
        </div>

        {/* Nav links */}
        <div
          className={`items-center justify-between w-full md:flex md:w-auto md:order-1 ${
            isMenuOpen ? "block" : "hidden"
          }`} // Dinamik olarak menünün görünürlüğünü kontrol ediyoruz
          id="navbar-cta"
        >
          <ul className="flex flex-col font-medium p-4 md:p-0 mt-4 border border-gray-100 rounded-lg md:space-x-12 md:flex-row md:mt-0 md:border-0">
            <li>
              <a
                onClick={() => navigate("/")}
                className="cursor-pointer block py-2 px-3 md:p-0 text-black rounded md:bg-transparent md:text-green-600 font-semibold"
                aria-current="page"
              >
                Home
              </a>
            </li>
            <li>
              <a
                onClick={() => navigate("/about")}
                className="cursor-pointer block py-2 px-3 md:p-0 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-green-700 font-semibold"
              >
                About
              </a>
            </li>
            <li>
              <a
                onClick={() => navigate("/pricing")}
                className="cursor-pointer block py-2 px-3 md:p-0 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-green-700 font-semibold"
              >
                Pricing
              </a>
            </li>
            <li>
              <a
                onClick={() => navigate("/ai-converter")}
                className="cursor-pointer block py-2 px-3 md:p-0 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-green-700 font-semibold"
              >
                AI Converter{" "}
                <span className="text-[9px] text-blue-500 transform-cpu font-normal">
                  Soon
                </span>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Nav;
