import React from "react";
import Config from "../config/Config";

const Footer = () => {
  return (
    <footer className="w-full my-4">
      <div className="mx-auto">
      <hr className="w-screen"></hr>
        <span className="block text-sm text-gray-500 font-light  text-center dark:text-gray-400 mt-3">
          © 2024{" "}
          <a href={Config.url} className="hover:underline">
            ImageConverter™
          </a>
          . Developed with ❤️
        </span>
      </div>
    </footer>
  );
};

export default Footer;
